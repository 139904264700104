import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  loadScript(src: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.type = 'module';

      script.onload = () => resolve(true);
      script.onerror = () => reject(false);
      document.head.appendChild(script);
    });
  }
}
