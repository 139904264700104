import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChartColor } from '@app/modules/chart/models/chart.enums';
import {
  CustomChartDataset,
  BarChartData,
  DefaultChartData,
  CustomTooltipOptions,
} from '@app/modules/chart/models/chart.interfaces';
import {
  EnergyType,
  VolumeQuality,
  Volume,
  VolumeResolution,
} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { EnergyUnit } from '@app/shared/models/units.interface';
import {
  DeliveryPoint,
  EliqAccessRights,
  Profile,
} from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MobileScreenService } from '@app/shared/services/mobile-screen.service';
import { Observable, Subject, combineLatest, filter, share, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MeterConfig } from '@app/shared/models/meter.interface';
import { DatepickerI18n } from '@app/modules/customer-zone/tevc-monitoring/services/datepicker-i18n';
import { VolumesService } from '@app/modules/customer-zone/consumption/services/volumes/volumes.service';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { Direction } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import moment from 'moment';

interface ChartDataStructuresObject {
  consumptions: BarChartData[];
  estimations: BarChartData[];
  injections?: BarChartData[];
}

@Component({
  selector: 'app-monthly-consumption-chart-widget',
  templateUrl: './monthly-consumption-chart-widget.component.html',
  styleUrls: ['./monthly-consumption-chart-widget.component.scss'],
})
export class MonthlyConsumptionChartWidgetComponent implements OnInit, OnDestroy {
  @Input() reference: string;
  @Input() site: Site;
  @Input() deliveryPoints: DeliveryPoint[];
  @Input() meterConfig = MeterConfig.basic;
  @Input() eliqAccessRights: EliqAccessRights;

  public profile: Profile;
  public energyOptions: EnergyType[] = [];
  public displayInjection = false;
  public activeEnergyType: EnergyType;
  public currentYear = moment().format('YYYY');
  public deliveryPoint: DeliveryPoint;
  public tooltipOptions: CustomTooltipOptions = {};

  public EnergyType = EnergyType;
  public EnergyUnit = EnergyUnit;
  public MeterConfig = MeterConfig;

  public notifier$ = new Subject<void>();
  public isMobile$: Observable<boolean>;
  public volumes$: Observable<Volume[][]>;

  public consumptionBarCharDatasets: CustomChartDataset<BarChartData>[];

  public electricityChartData: ChartDataStructuresObject;
  public gasChartData: ChartDataStructuresObject;

  constructor(
    private readonly translateService: TranslateService,
    private readonly mobileScreenService: MobileScreenService,
    private readonly datepickerI18n: DatepickerI18n,
    private readonly volumesService: VolumesService
  ) {}

  ngOnInit(): void {
    this.resetChartData();

    this.isMobile$ = this.mobileScreenService.getIsMobile();

    this.translateService.onLangChange.pipe(takeUntil(this.notifier$)).subscribe(() => {
      this.createChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.meterConfig?.currentValue) {
      this.displayInjection = this.meterConfig === MeterConfig.smartAndSolar;
      this.tooltipOptions.showEstimationWarning = this.meterConfig === MeterConfig.basic;
    }

    if (changes?.deliveryPoints?.currentValue?.length) {
      // reset chart data (important for new volume API calling)
      this.resetChartData();

      this.deliveryPoints = changes.deliveryPoints.currentValue;

      // reset active energy to electricity if exists in delivery point
      this.activeEnergyType = this.deliveryPoints.find(
        (deliveryPoint: DeliveryPoint) => deliveryPoint.energy === EnergyType.ELECTRICITY
      )
        ? EnergyType.ELECTRICITY
        : EnergyType.GAS;

      this.energyOptions = [...new Set(this.deliveryPoints.map((value: DeliveryPoint) => value.energy))];

      this.deliveryPoint = this.deliveryPoints.find(({ energy }) => energy === this.activeEnergyType);
      this.profile = this.eliqAccessRights?.profileByDeliveryPointReference[this.deliveryPoint?.reference];

      this.loadVolumes();
    }
  }

  public toggleInjection(value: boolean) {
    this.displayInjection = value;
    this.createChart();
  }

  public switchEnergy(energyType: EnergyType) {
    this.activeEnergyType = energyType;
    this.deliveryPoint = this.deliveryPoints.find(({ energy }) => energy === this.activeEnergyType);
    this.profile = this.eliqAccessRights?.profileByDeliveryPointReference[this.deliveryPoint?.reference];

    this.loadVolumes();
  }

  public hasValues(data) {
    return data.some((vol) => vol.length);
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  private loadVolumes() {
    // init loading by active energy type
    this.activeEnergyType === EnergyType.ELECTRICITY ? this.loadElectricityVolumes() : this.loadGasVolumes();
  }

  private loadElectricityVolumes(): void {
    const electricityVolumes$: Observable<Volume[]>[] = [];

    // load electricity consumptions
    if (!this.electricityChartData.consumptions) {
      electricityVolumes$.push(this.callGetVolumeMethod());
    }

    // load electricity estimations
    if (!this.electricityChartData.estimations) {
      electricityVolumes$.push(this.callGetVolumeMethod(Direction.offtake, true));
    }

    // load electricity injections
    if (this.meterConfig === MeterConfig.smartAndSolar && !this.electricityChartData.injections) {
      electricityVolumes$.push(this.callGetVolumeMethod(Direction.injection));
    }

    this.loadVolumesOrRefreshChart(electricityVolumes$);
  }

  private loadGasVolumes(): void {
    const gasVolumes$: Observable<Volume[]>[] = [];

    // load electricity consumptions
    if (!this.gasChartData.consumptions) {
      gasVolumes$.push(this.callGetVolumeMethod());
    }

    // load electricity estimations
    if (!this.gasChartData.estimations) {
      gasVolumes$.push(this.callGetVolumeMethod(Direction.offtake, true));
    }

    this.loadVolumesOrRefreshChart(gasVolumes$);
  }

  private loadVolumesOrRefreshChart(volumes$: Observable<Volume[]>[]): void {
    if (volumes$.length) {
      // load volume data from API
      this.volumes$ = combineLatest(volumes$).pipe(
        // use share operator for sharing source with observableWithLoading Pipe (prevention for multiple API calls)
        share(),
        takeUntil(this.notifier$)
      );
      this.volumes$.subscribe((volumeArray: Volume[][]) => {
        this.prepareChartData(volumeArray);
        this.createChart();
      });
    } else {
      // reload chart only
      this.createChart();
    }
  }

  private callGetVolumeMethod(type = Direction.offtake, estimation = false): Observable<Volume[]> {
    return this.volumesService.getVolumes({
      reference: this.reference,
      siteId: this.site.id,
      energyType: this.activeEnergyType,
      deliveryPoint: this.deliveryPoint?.reference,
      resolution: VolumeResolution.Month,
      fromDate: moment().startOf('year').toDate(),
      toDate: moment().add(1, 'years').startOf('year').toDate(),
      type: type.toLowerCase(),
      ...(estimation ? { estimate: true } : {}),
    });
  }

  private resetChartData(): void {
    this.electricityChartData = {
      consumptions: null,
      estimations: null,
      injections: null,
    };

    this.gasChartData = {
      consumptions: null,
      estimations: null,
    };
  }

  private createChart(): void {
    // reset data before re-generating
    this.consumptionBarCharDatasets = [];

    this.consumptionBarCharDatasets = this.prepareChartDatasets();
  }

  private getChartDataFromVolume(volumes: Volume[]): BarChartData[] {
    const currentMonthIndex = new Date().getMonth();
    const chartData: BarChartData[] = new Array(12).fill({ value: 0 });

    volumes.forEach((volume: Volume) => {
      const index = moment(volume.previousMeteringDate).month();

      const currentMonthStyle = index === currentMonthIndex ? { fontColor: ChartColor.redDefault, bold: true } : {};

      chartData[index] = {
        value: Math.round(volume.volumes[0].value),
        ...currentMonthStyle,
      };
    });

    return chartData;
  }

  private prepareChartData(volumesArray: Volume[][]): void {
    const preparedChartData = {
      consumptions: [],
      estimations: [],
      injections: [],
    };

    // array of consumptions, estimations and injections (optional)
    if (volumesArray?.length) {
      volumesArray.forEach((volumes: Volume[]) => {
        if (volumes && volumes.length) {
          const chartData = this.getChartDataFromVolume(volumes);
          // estimations data
          if (volumes.some((volume: Volume) => volume.volumes[0].quality === VolumeQuality.Estimated)) {
            preparedChartData.estimations = chartData;
          } else {
            // injections data
            if (volumes.some((volume: Volume) => volume.volumes[0].direction === Direction.injection)) {
              // data for injection should be negative
              preparedChartData.injections = chartData.map((item: BarChartData) => ({
                ...item,
                // injection data are always negative
                value: -Math.abs(item.value),
              }));
            } else {
              // consumptions data
              preparedChartData.consumptions = chartData;
            }
          }
        }
      });
    }

    if (this.activeEnergyType === EnergyType.ELECTRICITY) {
      this.electricityChartData = preparedChartData;
    } else {
      this.gasChartData = preparedChartData;
    }
  }

  private prepareChartDatasets(): CustomChartDataset<BarChartData>[] {
    if (this.activeEnergyType === EnergyType.ELECTRICITY) {
      // set or update labels (important after changing the language)
      this.electricityChartData.consumptions = this.updateChartDatatLabels(this.electricityChartData.consumptions);
      this.electricityChartData.estimations = this.updateChartDatatLabels(
        this.normalizeEstimationData(this.electricityChartData.estimations)
      );
      this.electricityChartData.injections = this.updateChartDatatLabels(this.electricityChartData.injections);

      const consumptionDataset = [
        {
          data: this.electricityChartData.consumptions,
          backgroundColor: ChartColor.lightBlue,
          hoverBackgroundColor: ChartColor.grayBlue,
          label: this.translateService.instant('components.widgets.monthlyConsumption.chart.tooltip.consumption'),
          legendLabel: this.translateService.instant('components.widgets.monthlyConsumption.chart.legend.consumption'),
        },
      ];

      const injectionDataset = [
        {
          data: this.electricityChartData.injections,
          backgroundColor: ChartColor.yellow,
          hoverBackgroundColor: ChartColor.grayYellow,
          label: this.translateService.instant('components.widgets.monthlyConsumption.chart.tooltip.injection'),
          dashedBorder: true,
          legendLabel: this.translateService.instant('components.widgets.monthlyConsumption.chart.legend.injection'),
        },
      ];

      const estimationDataset = [
        {
          data: this.electricityChartData.estimations,
          backgroundColor: ChartColor.lightBlue10,
          hoverBackgroundColor: ChartColor.lightBlue30,
          borderColor: ChartColor.lightBlue,
          dashedBorder: true,
          label: this.translateService.instant('components.widgets.monthlyConsumption.chart.tooltip.estimation'),
          legendLabel: this.translateService.instant('components.widgets.monthlyConsumption.chart.legend.estimation'),
        },
      ];

      return [
        ...(this.electricityChartData.consumptions?.length ? consumptionDataset : []),
        ...(this.electricityChartData.estimations?.length ? estimationDataset : []),
        ...(this.displayInjection && this.electricityChartData.injections?.length ? injectionDataset : []),
      ];
    } else {
      // set or update labels (important after changing the language)
      this.gasChartData.consumptions = this.updateChartDatatLabels(this.gasChartData.consumptions);
      this.gasChartData.estimations = this.updateChartDatatLabels(
        this.normalizeEstimationData(this.gasChartData.estimations)
      );

      const consumptionDataset = [
        {
          data: this.gasChartData.consumptions,
          backgroundColor: ChartColor.darkBlue,
          hoverBackgroundColor: ChartColor.darkGrayBlue,
          label: this.translateService.instant('components.widgets.monthlyConsumption.chart.tooltip.consumption'),
          legendLabel: this.translateService.instant('components.widgets.monthlyConsumption.chart.legend.consumption'),
        },
      ];

      const estimationDataset = [
        {
          data: this.gasChartData.estimations,
          backgroundColor: ChartColor.darkBlue10,
          hoverBackgroundColor: ChartColor.darkBlue30,
          borderColor: ChartColor.darkBlue,
          dashedBorder: true,
          label: this.translateService.instant('components.widgets.monthlyConsumption.chart.tooltip.estimation'),
          legendLabel: this.translateService.instant('components.widgets.monthlyConsumption.chart.legend.estimation'),
        },
      ];

      return [
        ...(this.gasChartData.consumptions?.length ? consumptionDataset : []),
        ...(this.gasChartData.estimations?.length ? estimationDataset : []),
      ];
    }
  }

  private normalizeEstimationData(dataArray: DefaultChartData[]): DefaultChartData[] {
    const currentMonth = moment().month();
    if (dataArray) {
      dataArray.map((item: DefaultChartData, index: number) => {
        // estimation data are allowed for current or higher
        item.value = index >= currentMonth ? item.value : 0;
        return item;
      });
    }
    return dataArray;
  }

  private updateChartDatatLabels(dataArray: DefaultChartData[]): DefaultChartData[] {
    if (dataArray) {
      dataArray.map((item: DefaultChartData, index: number) => {
        item.label = this.datepickerI18n.getMonthShortName(index + 1);
        return item;
      });
    }
    return dataArray;
  }
}
