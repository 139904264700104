import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MoveFormComponent } from '@app/modules/customer-zone/move/components/move-form/move-form.component';
import { SupplierComponent } from '@app/modules/customer-zone/move/components/move-form/steps/supplier/supplier.component';
import { MovingInfoComponent } from '@app/modules/customer-zone/move/components/move-form/steps/moving-info/moving-info.component';
import { MoveFormPaths } from '@app/core/models/paths';
import { MyMetersComponent } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/my-meters.component';
import { NewAddressComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-address/new-address.component';
import { NewMetersComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/new-meters.component';
import { NewOccupantComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-occupant/new-occupant.component';
import { FinaliseComponent } from '@app/modules/customer-zone/move/components/move-form/steps/finalise/finalise.component';
import { BillingInfoComponent } from '@app/modules/customer-zone/move/components/move-form/steps/billing-info/billing-info.component';
import { MoveDeliveryPointService } from './components/move-form/steps/my-meters/move-delivery-point.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

const routes: Routes = [
  {
    path: '',
    component: MoveFormComponent,
    providers: [MoveDeliveryPointService],
    children: [
      { path: '', redirectTo: MoveFormPaths.supplier, pathMatch: 'full' },
      { path: MoveFormPaths.supplier, component: SupplierComponent },
      { path: MoveFormPaths.movingInfo, component: MovingInfoComponent },
      { path: MoveFormPaths.myMeters, component: MyMetersComponent },
      { path: MoveFormPaths.newAddress, component: NewAddressComponent },
      { path: MoveFormPaths.billingInfo, component: BillingInfoComponent },
      { path: MoveFormPaths.newMeters, component: NewMetersComponent },
      { path: MoveFormPaths.newOccupant, component: NewOccupantComponent },
      { path: MoveFormPaths.finalise, component: FinaliseComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MoveFormRoutingModule {}

_('pages.move.pageTitle');
_('components.move.dashboard.statusProgress.missingInfo');
_('components.move.dashboard.statusProgress.completed');
_('components.move.dashboard.statusProgress.sent');
_('components.move.dashboard.statusProgress.processed');
_('components.move.dashboard.statusProgress.finalised');
_('components.move.dashboard.cta.launchMove');
_('components.move.dashboard.card.alerts.missingInfo');
_('components.move.dashboard.card.labels.elecMeter');
_('components.move.dashboard.card.labels.gasMeter');
_('components.move.dashboard.card.labels.selectedMeters');
_('components.move.dashboard.card.labels.newAddress');
_('components.move.dashboard.cta.moveOtherMeters');
_('components.move.dashboard.card.labels.moving');
_('components.move.form.title');
_('components.move.form.step.supplier');
_('components.move.form.step.moving-info');
_('components.move.form.step.my-meters');
_('components.move.form.step.new-address');
_('components.move.form.step.billing-info');
_('components.move.form.step.new-meters');
_('components.move.form.step.new-occupant');
_('components.move.form.step.finalise');
_('components.move.form.button.previous');
_('components.move.form.button.next');
_('components.move.form.button.finalize');
_('components.move.form.button.saveForLater');
_('components.move.form.button.cancelMyMove');
_('components.move.form.banner.leavingUser');
_('components.move.form.error.responseError.title');
_('components.move.form.error.responseError.body');
_('components.move.form.myCurrentAddress');
