import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-loader-widget',
  templateUrl: './loader-widget.component.html',
  styleUrls: ['./loader-widget.component.scss'],
  imports: [TranslateModule],
  standalone: true,
})
export class LoaderWidgetComponent implements OnInit {
  @Input() showBorder = true;

  constructor() {}

  ngOnInit(): void {}
}
