import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { catchError, distinctUntilChanged, filter, of, Subject, switchMap, takeUntil } from 'rxjs';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { ErrorAccessComponent, ErrorAccessConfig } from '@app/shared/components/error-access/error-access.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-error-access-eliq',
  templateUrl: './error-access-eliq.component.html',
  styleUrls: ['./error-access-eliq.component.scss'],
  imports: [ErrorAccessComponent],
  standalone: true,
})
export class ErrorAccessEliqComponent implements OnInit, OnDestroy {
  private notifier: Subject<void> = new Subject();
  config: ErrorAccessConfig = {
    title: 'components.pageError.consumptionAccess.title',
    content: 'components.pageError.consumptionAccess.description',
    ctaLink: '/consumptions/',
    ctaLabel: 'components.pageError.consumptionAccess.back',
    navigateExternalSite: false,
  };

  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    this.facade.activeSite$
      .pipe(
        takeUntil(this.notifier),
        filter((site: Site) => !!site),
        distinctUntilChanged(),
        switchMap((site: Site) => this.facade.loadEliqAccessRights(this.facade.state$.value.reference, site)),
        catchError(() => of(null))
      )
      .subscribe((access: EliqAccessRights) => {
        const currentURL = window.location.href;
        if (currentURL.includes('consumptions')) {
          if (
            access &&
            access?.widgetAccessPerDeliveryPointReference?.length &&
            Object.keys(access?.accessPerSiteId).length
          ) {
            this.backToConsumptions();
          }
        }
      });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  backToConsumptions() {
    this.facade.utils.redirectTo(this.config.ctaLink);
  }
}

_('components.pageError.consumptionAccess.title');
_('components.pageError.consumptionAccess.description');
_('components.pageError.consumptionAccess.back');
