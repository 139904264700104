import { Injectable } from '@angular/core';
import { DestroyRef } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ExnatonService } from '@app/modules/customer-zone/exnaton/exnaton.service';

@Injectable()
export class DynamicTariffService {
  constructor(
    private facade: MainFacade,
    private translateService: TranslateService,
    private exnatonService: ExnatonService,
    private destroyRef: DestroyRef
  ) {}

  get scriptLoaded$(): Observable<boolean | null> {
    return this.exnatonService.scriptLoaded$;
  }

  updateConfigAfterRefAndLangChanges(): void {
    this.translateService.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event: LangChangeEvent) => {
      if (event?.lang) {
        this.exnatonService.updateExnatonConfig(this.facade.state$.value.activeSite.id);
      }
    });

    this.facade.accessDynamicTariffPerRef$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((access: boolean) => {
      if (access) {
        this.exnatonService.updateExnatonConfig(this.facade.state$.value.activeSite.id);
      }
    });
  }
}
