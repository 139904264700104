import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChargingStationSessionReport } from '../../models/charging-station.interface';
import { Observable } from 'rxjs/internal/Observable';
import { Chart, ChartConfiguration } from 'chart.js';
import { DecimalPipe, formatDate, TitleCasePipe } from '@angular/common';
import { filter } from 'rxjs';
import { takeUntil } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Granularity, SwitchOption } from '../../models/tevc.constants';
import moment from 'moment';
import { AnnotationOptions, AnnotationTypeRegistry } from 'chartjs-plugin-annotation';
import { _DeepPartialArray, _DeepPartialObject } from 'chart.js/dist/types/utils';

@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.scss'],
})
export class MonthlyReportComponent implements OnInit, OnChanges {
  @Input() chargingStationSessionReports$: Observable<ChargingStationSessionReport[]>;
  @Input() activeGranularityFilter: Granularity = Granularity.year;
  @Input() activeSwitchOption: SwitchOption;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  locale: string;

  private data: Array<number>;
  private labels: Array<string>;
  private averageNumber: number;
  private annotations: {
    type: string;
    scaleID: string;
    value: number;
    borderColor: string;
    borderWidth: 1;
    borderDash?: number[];
    label: {
      backgroundColor: string;
      color: string;
      font: {
        family: string;
        style?: string;
      };
      content: string;
      enabled: boolean;
      cornerRadius?: number;
      position?: string;
      padding?: number;
      yAdjust?: number;
      xAdjust?: number;
    };
  }[];

  private ctx: CanvasRenderingContext2D;
  private chart: Chart;
  private notifier$: Subject<any> = new Subject();

  constructor(
    private decimalPipe: DecimalPipe,
    private titleCasePipe: TitleCasePipe,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setLocale();
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.prepareDataForChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('activeSwitchOption')) {
      this.prepareDataForChart();
    }
  }

  private setLocale(): void {
    this.locale = this.translate.currentLang + '-BE';
    this.translate.onLangChange.pipe(takeUntil(this.notifier$)).subscribe((value) => {
      this.locale = value.lang + '-BE';
      // when the language changes, we need to take care of translations in the chart and redraw the chart
      this.annotations[0].label.content = this.getAverageTranslationString();
      this.redrawTheChart();
    });
  }

  private prepareDataForChart(): void {
    this.chargingStationSessionReports$
      .pipe(filter((data) => data !== null && data !== undefined))
      .subscribe((data) => {
        // we need to empty the arrays so we can replace values with new data
        this.data = [];
        this.labels = [];
        this.annotations = [];
        let dataSum = 0;

        data.forEach((value) => {
          this.data.push(this.activeSwitchOption === SwitchOption.energy ? value.totalEnergy / 1000 : value.totalPrice);
          dataSum += this.activeSwitchOption === SwitchOption.energy ? value.totalEnergy / 1000 : value.totalPrice;
          this.labels.push(value.start);
        });
        this.prepareTheAnnotations(data, dataSum);

        // draw the chart
        this.redrawTheChart();
      });
  }

  private prepareTheAnnotations(data: ChargingStationSessionReport[], dataSum: number): void {
    // calculate the average and display the average as annotation
    this.averageNumber = dataSum / this.data.length;
    this.annotations.push({
      type: 'line',
      scaleID: 'y',
      value: this.averageNumber,
      borderColor: '#DB3900',
      borderWidth: 1,
      label: {
        backgroundColor: 'rgba(247, 249, 250, 0.9)',
        color: 'rgba(0, 0, 0, 0.7)',
        font: {
          family: '"Roboto", Arial, Helvetica, sans-serif',
        },
        content: this.getAverageTranslationString(),
        enabled: true,
        cornerRadius: 15,
        position: 'end',
        padding: 10,
        yAdjust: -20,
        xAdjust: 0,
      },
    });

    // if granularity filter is '12 months' or '24 months', we display another annotation - the years divider
    if (
      this.activeGranularityFilter === Granularity.months12 ||
      this.activeGranularityFilter === Granularity.months24
    ) {
      const dividers: Array<{ position: number; year: number }> = [];
      // we need to get the positions of December (we may have two Decembers when 24 months are displayed) in the array
      // months are numbered from 0
      data.forEach((session) => {
        if (moment(session.start).toDate().getMonth() + 1 === 12) {
          const position = data.findIndex((value) => value === session);
          dividers.push({
            position,
            year: moment(data[position].start).toDate().getFullYear(),
          });
        }
      });

      dividers.forEach((divider) => {
        this.annotations.push({
          type: 'line',
          scaleID: 'x',
          value: divider.position + 0.5,
          borderColor: 'rgba(0, 0, 0, 0.4)',
          borderWidth: 1,
          borderDash: [5, 10],
          label: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0.4)',
            font: {
              style: 'normal',
              family: '"Roboto", Arial, Helvetica, sans-serif',
            },
            content: divider.year + '   ' + (divider.year + 1),
            enabled: true,
            position: 'start',
          },
        });
      });
    }
  }

  private getAverageTranslationString(): string {
    return (
      this.translate.instant('components.monitoring.monthlyReport.average') +
      ' ' +
      (this.activeSwitchOption === SwitchOption.energy
        ? this.decimalPipe.transform(this.averageNumber, '1.0-2') + ' kWh'
        : this.decimalPipe.transform(this.averageNumber, '1.2-2') + ' €')
    );
  }

  private redrawTheChart(): void {
    // if chart already exists it needs to be destroyed before redrawing
    if (this.chart) {
      this.chart.destroy();
    }

    const chartConfiguration: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: this.labels.map((label) => this.titleCasePipe.transform(formatDate(label, 'MMM', this.locale))),
        datasets: [
          {
            fill: true,
            data: this.data,
            backgroundColor: '#92EAEA',
            hoverBackgroundColor: '#92EAEA',
            categoryPercentage: 1,
            borderRadius: 10,
          },
        ],
      },
      options: {
        responsive: false,
        elements: {
          line: {
            tension: 0.5,
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                family: '"Roboto", Arial, Helvetica, sans-serif',
              },
            },
            grid: {
              display: true,
              //drawOnChartArea: false,
            },
          },
          y: {
            ticks: {
              font: {
                family: '"Roboto", Arial, Helvetica, sans-serif',
              },
              callback:
                this.activeSwitchOption === SwitchOption.energy ? (value) => value + ' kWh ' : (value) => value + ' € ',
            },
            // inner grid is not visible, only the y axis
            grid: {
              display: true,
              drawOnChartArea: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: this.annotations as
              | _DeepPartialArray<AnnotationOptions<keyof AnnotationTypeRegistry>>
              | _DeepPartialObject<Record<string, AnnotationOptions<keyof AnnotationTypeRegistry>>>,
          },
          tooltip: {
            enabled: false,
            external: (tooltipModel) => this.customTooltipHandler(tooltipModel),
          },
        },
      },
    };

    this.chart = new Chart(this.ctx, chartConfiguration);
  }

  private customTooltipHandler(tooltipModel) {
    let tooltipElement = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipElement) {
      tooltipElement = document.createElement('div');
      tooltipElement.id = 'chartjs-tooltip';
      tooltipElement.innerHTML = '<div></div>';
      document.body.appendChild(tooltipElement);
    }

    // Hide if no tooltip
    if (tooltipModel.tooltip.opacity === 0) {
      tooltipElement.style.opacity = '0';
      return;
    }

    // Inner HTML
    const data = (this.chargingStationSessionReports$ as BehaviorSubject<ChargingStationSessionReport[]>).value[
      tooltipModel.tooltip.dataPoints[0].dataIndex
    ];

    let innerHtml = '<div style="display: flex; justify-content: space-between; font-weight: 500;">';
    innerHtml +=
      '<div style="font-size: 14px; color: #374649">' +
      this.titleCasePipe.transform(formatDate(data.start, 'MMMM yyyy', this.locale)) +
      '</div>';
    innerHtml +=
      '<div style="color: #B7CBD3; font-size: 14px; text-align: right;">' + '#' + data.nbSession + '</div></div>';
    innerHtml += '<div style="display: flex; justify-content: space-between; margin-top: 2rem; font-weight: 500;">';
    innerHtml +=
      '<div style="color: #879092; font-size: 16px; font-weight: 500; width: 50%; ' +
      'border-right: 1px solid #B7CBD3;">' +
      this.decimalPipe.transform(data.totalPrice, '1.2-2') +
      ' €' +
      '</div>';
    innerHtml +=
      '<div style="#374649; font-size: 16px; text-align: right; width: 50%; font-weight: 500;">' +
      this.decimalPipe.transform(data.totalEnergy / 1000, '1.0-2') +
      ' kWh' +
      '</div>';
    innerHtml += '</div>';

    const tableRoot = tooltipElement.querySelector('div');
    tableRoot.innerHTML = innerHtml;

    const position = tooltipModel.chart.canvas.getBoundingClientRect();
    tooltipElement.style.opacity = '1';
    tooltipElement.style.position = 'absolute';
    tooltipElement.style.backgroundColor = 'rgb(266, 255, 255)';
    tooltipElement.style.borderColor = 'rgba(0, 0, 0, 0.1)';
    tooltipElement.style.borderWidth = '1px';
    tooltipElement.style.borderRadius = '5px';
    tooltipElement.style.width = '24rem';
    tooltipElement.style.fontStyle = 'normal';
    tooltipElement.style.padding = '1.5rem';
    tooltipElement.style.pointerEvents = 'none';

    const { offsetLeft: positionX, offsetTop: positionY } = tooltipModel.chart.canvas;
    // Display, position, and set styles for font

    const chartHeight = position.height;
    const barHeight = chartHeight - tooltipModel.caretY;
    const tooltipHeight = tooltipElement.offsetHeight;
    const offsetY = 15;
    const tooltipOffsetY = barHeight < tooltipHeight + offsetY ? barHeight / 2 - offsetY - tooltipHeight / 2 : offsetY;

    tooltipElement.style.opacity = '1';
    tooltipElement.style.left = positionX + tooltipModel.tooltip.caretX + 'px';
    tooltipElement.style.top = positionY + tooltipModel.tooltip.caretY + tooltipOffsetY + 'px';
    tooltipElement.style.font = tooltipModel.tooltip.options.bodyFont.string;
    tooltipElement.style.padding =
      tooltipModel.tooltip.options.padding + 'px ' + tooltipModel.tooltip.options.padding + 'px';
  }
}
