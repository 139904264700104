import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { filter, forkJoin, Observable, switchMap, take, timer } from 'rxjs';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { INITIAL_MOVE_STATE, InvoicingMethod, MoveDTO, MoveFormFrontend, MoveState } from '@app/core/state/move.state';
import { MainFacade } from '@app/core/facade/main.facade';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { MoveAddressComponent } from '@app/modules/customer-zone/move/components/move-form/parts/move-address/move-address.component';
import {
  Address,
  BillingMethod,
  DeliveryPoint,
  MoveInRegistration,
} from '@app/modules/customer-zone/move/models/movein.interface';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import { TranslateModule } from '@ngx-translate/core';
import { markFormGroupTouched } from '@app/shared/utils/utils.validators';
import { InvoicingMethodComponent } from '../../parts/invoicing-method/invoicing-method.component';
import { EnergyTypeComponent } from './energy-type/energy-type.component';
import dayjs from 'dayjs';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MoveAddressFormGroup, NewAddressStep, NewAddressStepFromGroup } from './new-address.interface';

@Component({
  selector: 'app-new-address',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NavigationComponent,
    ReactiveFormsModule,
    EnergyTypeComponent,
    MoveAddressComponent,
    TranslateModule,
    InvoicingMethodComponent,
  ],
  templateUrl: './new-address.component.html',
  styleUrls: ['./new-address.component.scss'],
})
export class NewAddressComponent extends MoveFormStep<MoveInRegistration> implements OnInit {
  readonly InvoicingMethod = InvoicingMethod;
  readonly AlertType = AlertType;
  form: FormGroup<NewAddressStepFromGroup>;
  billingEmail: string;
  fileName: string;
  defaultInvoiceDeliveryChannel: InvoicingMethod = InvoicingMethod.POST;
  registration: MoveInRegistration;
  submittedForm: boolean = false;
  moveOutDeliveryPoints: DeliveryPoint[];

  constructor(protected readonly moveFormFacade: MoveFormFacade, private readonly facade: MainFacade) {
    super(moveFormFacade);

    this.form = this.moveFormFacade.getMoveFormService().getNewAddressStepFormGroup();
  }

  ngOnInit(): void {
    this.setFormValues();
  }

  get invoicingMethodControl(): FormControl<InvoicingMethod> {
    return this.form.controls.invoicingData.controls.invoicingMethod;
  }

  setFormValues() {
    forkJoin([
      this.moveFormFacade.state$.pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      ),
      this.facade.loadBillingDetails(null, false).pipe(take(1)),
    ]).subscribe(([state, billingDetails]) => {
      this.registration = state?.registration;
      const formValues = state?.form?.newAddress;
      this.moveOutDeliveryPoints = state.moveDTO?.sites?.[0]?.deliveryPoints.sort((dp) =>
        dp.energyType === EnergyType.ELECTRICITY ? -1 : 1
      );

      this.form.controls.energyType.patchValue(formValues.energyType);
      if (formValues.registrationWasCreated) {
        this.form.controls.energyType.disable();
      }
      this.form.controls.requestedServiceDate.patchValue(formValues.requestedServiceDate);

      const { country, ...newAddress } = formValues?.newAddress;
      this.form.controls.newAddress.patchValue(newAddress);

      this.billingEmail = billingDetails.email;

      const typeOfFacturation = formValues?.invoicingData.invoicingMethod
        ? formValues?.invoicingData.invoicingMethod
        : (billingDetails.invoiceDeliveryChannel as InvoicingMethod);
      this.invoicingMethodControl.setValue(typeOfFacturation);

      if (billingDetails.invoiceDeliveryChannel === InvoicingMethod.EMAIL) {
        this.form.controls.invoicingData.controls.invoicingMethod.setValue(InvoicingMethod.EMAIL);
        this.form.controls.invoicingData.controls.invoicingMethod.disable();
        this.defaultInvoiceDeliveryChannel = InvoicingMethod.EMAIL;
      } else if (formValues?.invoicingData) {
        const values = formValues?.invoicingData;
        this.form.controls.invoicingData.patchValue({
          invoicingMethod: values?.invoicingMethod,
        });
      }
    });
  }

  onNextClickedDefault(): void {
    this.submittedForm = true;
    if (this.form.valid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      this.saveFormData().subscribe(() => {
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        this.moveFormFacade.next();
      });
    } else {
      markFormGroupTouched(this.form);
    }
  }

  onPreviousClickedDefault(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    this.moveFormFacade.updateData({ form: this.getForm() });
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.previous();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
  }

  saveFormData(): Observable<MoveInRegistration> {
    const moveFormService = this.moveFormFacade.getMoveFormService();
    const addressPayload: Address = moveFormService.getFormAddress(this.form.controls.newAddress);
    const moveDTO: MoveDTO = moveFormService.updatedBillingInfoMoveOut(
      this.moveFormFacade.state$.value.moveDTO,
      addressPayload,
      this.form.controls.invoicingData.controls.invoicingMethod.value
    );
    // First we update move out data
    return this.moveFormFacade.updateMoveOut(moveDTO).pipe(switchMap(() => this.handleMoveIn()));
  }

  handleMoveIn() {
    const state: MoveState = this.moveFormFacade.state$.value;
    const selectedEnergyType: EnergyType = this.form.controls.energyType.controls.electricity.value
      ? this.form.controls.energyType.controls.gas.value
        ? EnergyType.DUAL
        : EnergyType.ELECTRICITY
      : EnergyType.GAS;
    // We are creating new amendment (specific registration) if we don't have moveChainId
    // Amendment is created from move out data (we are not sending any payload for creating amendment)
    // Then we need to update registration with new data - Reason:
    // we update this data in move out (before this call) and normally this data should be used during creation of amendment on backend, but it is not this case
    // There is async issue on backend, data are not sucessfully saved on move out but we are requesting new amendment, because of that we need to update registration with new data
    return !!state.moveDTO?.moveChainId
      ? this.moveFormFacade.updateRegistration(this.mapFormToMoveIn(this.moveFormFacade.state$.value.registration))
      : this.moveFormFacade
          .setAmendment(state.moveDTO, selectedEnergyType)
          .pipe(
            switchMap((newRegistration: MoveInRegistration) =>
              this.moveFormFacade.updateRegistration(this.mapFormToMoveIn(newRegistration))
            )
          );
  }

  onInvoicingMethodChange(value: InvoicingMethod): void {
    this.invoicingMethodControl.setValue(value);
  }

  private mapFormToMoveIn(registration: MoveInRegistration): MoveInRegistration {
    let payload = structuredClone(registration);
    payload = this.setMoveInDateData(payload);
    payload.sites[0].address = {
      ...payload.sites[0].address,
      ...this.getAddress(this.form.controls.newAddress),
    };
    payload.billingInfo = {
      ...payload?.billingInfo,
      billingMethodType: BillingMethod.MONTHLY,
      electronicInvoicing:
        this.form.controls.invoicingData.controls.invoicingMethod.value === this.InvoicingMethod.EMAIL,
      invoiceSendingType: this.form.controls.invoicingData.controls.invoicingMethod.value,
      address: {
        ...payload?.billingInfo?.address,
        ...this.getAddress(this.form.controls.newAddress),
      },
    };
    return payload;
  }

  private setMoveInDateData(registration: MoveInRegistration): MoveInRegistration {
    const selectedDate = dayjs(this.form.controls.requestedServiceDate.value).format('YYYY-MM-DD');
    registration.sites[0].deliveryPoints.forEach((dp) => {
      dp.requestedServiceDate = selectedDate;
    });

    return registration;
  }

  private getAddress(formGroup: FormGroup<MoveAddressFormGroup>): Address {
    return {
      street: formGroup.controls.address.value,
      streetNumber: formGroup.controls.number.value,
      zip: formGroup.controls.zipCode.value,
      box: formGroup.controls.box.value,
      city: formGroup.controls.locality.value,
      country: formGroup.controls.country.value,
    };
  }

  private getForm(): MoveFormFrontend {
    return {
      ...this.moveFormFacade?.state$?.value?.form,
      newAddress: {
        ...this.moveFormFacade?.state$?.value?.form.newAddress,
        ...(this.form.value as NewAddressStep),
      },
    };
  }
}
