import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChartColor } from '@app/modules/chart/models/chart.enums';
import { CustomChartDataset, DoughnutChartData } from '@app/modules/chart/models/chart.interfaces';
import {
  ConsumptionApiError,
  EnergyType,
  Usage,
  UsageBreakdown,
} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MeterConfig } from '@app/shared/models/meter.interface';
import { EnergyUnit } from '@app/shared/models/units.interface';
import { Observable, Subject, share, takeUntil, map, catchError, of, throwError } from 'rxjs';
import moment from 'moment';
import { Contract } from '@app/modules/customer-zone/contracts/models/contract.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { Moment } from 'moment';
import { MobileScreenService } from '@app/shared/services/mobile-screen.service';
import { DeliveryPoint, EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';

interface EnergyTypeStructureObject {
  data: DoughnutChartData[];
  top: UsageBreakdown[];
  usage?: Usage;
}

interface ChartDataStructuresObject {
  electricity: EnergyTypeStructureObject;
  gas: EnergyTypeStructureObject;
}

@Component({
  selector: 'app-disaggregation-chart-widget',
  templateUrl: './disaggregation-chart-widget.component.html',
  styleUrls: ['./disaggregation-chart-widget.component.scss'],
})
export class DisaggregationChartWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() eliqAccessRights: EliqAccessRights;
  @Input() contracts: Contract[];
  @Input() meterConfig = MeterConfig.basic;
  @Input() deliveryPoints: DeliveryPoint[];

  public activeContract: Contract;
  public activeEnergyType: EnergyType;
  public showError = false;
  public showDisaggrationDetails = false;
  public showEnergyToggle = false;
  public currentPeriod: Moment;

  public EnergyType = EnergyType;
  public EnergyUnit = EnergyUnit;
  public MeterConfig = MeterConfig;
  public ConsumptionApiError = ConsumptionApiError;

  public usage$: Observable<Usage>;
  public isMobile$: Observable<boolean>;

  public doughnutChartDataset: CustomChartDataset<DoughnutChartData>;
  public topCategories: UsageBreakdown[] = [];

  constructor(readonly facade: MainFacade, private readonly mobileScreenService: MobileScreenService) {}

  public selectedEnergyUsage: Usage;

  public usageCharData: ChartDataStructuresObject;

  private notifier$ = new Subject<void>();

  ngOnInit(): void {
    this.resetChartData();

    this.isMobile$ = this.mobileScreenService.getIsMobile();

    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier$)).subscribe(() => {
      this.createChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.contracts?.currentValue?.length) {
      this.resetChartData();
      this.currentPeriod = moment().subtract('1', 'months');

      this.activeContract =
        this.contracts.find(({ type }) => type === EnergyType.ELECTRICITY) ||
        this.contracts.find(({ type }) => type === EnergyType.GAS);
      this.activeEnergyType = this.activeContract.type as EnergyType;

      // toggle energy button is available for smart meter only with both energies
      this.showEnergyToggle =
        this.meterConfig !== MeterConfig.basic &&
        [...new Set(this.deliveryPoints.map((value: DeliveryPoint) => value.energy))].length > 1;

      this.loadUsageData();
    }
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  public toggleEnergy(value: boolean) {
    this.activeEnergyType = value ? EnergyType.GAS : EnergyType.ELECTRICITY;
    this.activeContract = this.contracts.find(({ type }) => type === this.activeEnergyType);

    this.loadUsageData();
  }

  openPopupDetails() {
    this.showDisaggrationDetails = !this.showDisaggrationDetails;
    const appDisaggregation: Element | null = document.getElementsByTagName('app-disaggregation-chart-widget')[0];
    if (appDisaggregation) {
      (appDisaggregation as HTMLElement).style.zIndex = '30';
    }
  }

  private loadUsageData(): void {
    if (!this.getSelectedEnergyUsageChartData().data.length) {
      this.usage$ = this.facade
        .loadEnergyUsage(
          {
            reference: this.facade.state$.value.reference,
            siteId: this.facade.state$.value.activeSite?.id,
            energyType: this.activeEnergyType,
            deliveryPoint: this.activeContract.deliveryPointReference,
            fromDateString: this.currentPeriod.startOf('month').format('YYYY-MM-DD'),
            toDateString: moment().startOf('month').format('YYYY-MM-DD'),
          },
          false
        )
        .pipe(
          map((usage: Usage): Usage => {
            if (!usage) {
              return usage;
            }
            const categories: UsageBreakdown[] = usage?.breakdown.sort(
              (a: UsageBreakdown, b: UsageBreakdown) => b.value - a.value
            );
            const topCategories: UsageBreakdown[] = categories?.slice(0, 3);
            let otherCategories: UsageBreakdown[] = categories?.slice(3, categories.length);
            if (otherCategories.length <= 1) {
              return usage;
            }
            const orderedCategories: UsageBreakdown[] = this.orderCategories(topCategories, otherCategories);
            return { ...usage, breakdown: orderedCategories };
          }),
          catchError((e) => throwError(e)),
          share(),
          takeUntil(this.notifier$)
        );
      this.usage$.subscribe((usage) => {
        this.prepareChartData(usage);
        this.createChart();
      });
    } else {
      this.selectedEnergyUsage = this.getSelectedEnergyUsageChartData().usage;
      this.createChart();
    }
  }

  /* Order categories to separate 3 mains categories and move smaller ones in-between */
  private orderCategories(topCategories: UsageBreakdown[], otherCategories: UsageBreakdown[]): UsageBreakdown[] {
    if (otherCategories.length >= topCategories.length) {
      let nbCatleft: number = otherCategories.length % topCategories.length;
      const nbCatInBetween: number = (otherCategories.length - nbCatleft) / topCategories.length;
      return topCategories.reduce((newArray: UsageBreakdown[], category: UsageBreakdown) => {
        newArray.push(category);
        for (let i = 0; i < nbCatInBetween; i++) {
          if (i % 2 === 0) {
            newArray.push(otherCategories.shift());
          } else {
            newArray.push(otherCategories.pop());
          }
        }
        if (nbCatleft > 0) {
          newArray.push(otherCategories.pop());
          nbCatleft--;
        }
        return newArray;
      }, []);
    } else {
      const ordered: UsageBreakdown[] = [...topCategories];
      ordered.splice(1, 0, otherCategories[0]);
      ordered.push(otherCategories[1]);
      return ordered;
    }
  }

  private resetChartData(): void {
    this.usageCharData = {
      electricity: { data: [], top: [] },
      gas: { data: [], top: [] },
    };
  }

  private createChart(): void {
    // reset data before re-generating
    this.doughnutChartDataset = null;

    this.doughnutChartDataset = this.prepareChartDataset();
  }

  private prepareChartData(usage: Usage): void {
    if (usage?.breakdown) {
      const selectedUsageChartData = this.getSelectedEnergyUsageChartData();

      // cache and set selected energy usage for usage categories popup
      selectedUsageChartData.usage = usage;
      this.selectedEnergyUsage = usage;

      const disaggregationData: DoughnutChartData[] = [];

      // copy breakdown array
      const topCategories = [...this.selectedEnergyUsage.breakdown];
      // sort copied array and slice it (only top 3 highest categories are returned)
      selectedUsageChartData.top = topCategories
        .sort((a: UsageBreakdown, b: UsageBreakdown) => b.value - a.value)
        .slice(0, 3);

      // define colors by energy type and top categories
      const defaultCategoryColor =
        this.activeEnergyType === EnergyType.ELECTRICITY ? ChartColor.lightBlue60 : ChartColor.darkBlue30;
      const topCategoryColor =
        this.activeEnergyType === EnergyType.ELECTRICITY ? ChartColor.lightBlue : ChartColor.darkBlue;

      usage.breakdown.forEach((breakdown: UsageBreakdown) => {
        const isTopCategory =
          selectedUsageChartData.top.findIndex((item: UsageBreakdown) => item.category === breakdown.category) > -1;

        disaggregationData.push({
          type: breakdown.category,
          value: breakdown.percentage < 1 ? breakdown.percentage : Math.round(breakdown.percentage),
          label: this.facade.translate.instant(`components.usageCategoryPopup.categories.${breakdown.category}`),
          color: isTopCategory ? topCategoryColor : defaultCategoryColor,
          fontColor: isTopCategory ? ChartColor.gray : ChartColor.silver,
          image: isTopCategory ? `./assets/img/icons/standalone/white/${breakdown.category}.svg` : null,
          unitSeparator: ' ', // TODO: format the number with unit in Angular directly?
        });
      });

      selectedUsageChartData.data = disaggregationData;
    }
  }

  private prepareChartDataset(): CustomChartDataset<DoughnutChartData> {
    const selectedUsageChartData = this.getSelectedEnergyUsageChartData();

    // set or update labels (important after changing the language)
    selectedUsageChartData.data = this.updateChartDatatLabels(selectedUsageChartData.data, selectedUsageChartData.top);

    // set top categories to the field for the mobile view  chart
    this.topCategories = selectedUsageChartData.top;

    return {
      data: selectedUsageChartData.data,
    };
  }

  private getSelectedEnergyUsageChartData(): EnergyTypeStructureObject {
    return this.usageCharData[this.activeEnergyType.toLocaleLowerCase()] as EnergyTypeStructureObject;
  }

  private updateChartDatatLabels(dataArray: DoughnutChartData[], topCategories: UsageBreakdown[]): DoughnutChartData[] {
    if (dataArray) {
      dataArray.map((item: DoughnutChartData) => {
        item.label = this.facade.translate.instant(`components.usageCategoryPopup.categories.${item.type}`);
        return item;
      });
    }
    return dataArray;
  }
}
