import { Directive, Input, OnInit, ViewContainerRef, TemplateRef, DestroyRef } from '@angular/core';
import {
  AccessRight,
  AccessRightOperator,
  AccessRights,
} from '../resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  standalone: true,
  selector: '[accessControl]',
})
export class AccessControlDirective implements OnInit {
  protected accessRights: AccessRights;
  private accessRight: AccessRight;

  constructor(
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    protected facade: MainFacade,
    protected destroyRef: DestroyRef
  ) {}

  @Input() set accessControl(access: AccessRight) {
    this.accessRight = access;
    if (access) {
      this.updateView(this.checkAccess(this.accessRight));
    }
  }

  ngOnInit() {
    this.facade.accessRights$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((accessRights: AccessRights) => {
      if (!accessRights) {
        return;
      }
      // current access backup
      const previousAccess = this.checkAccess(this.accessRight);

      // set new access rights
      this.accessRights = accessRights;

      const currentAccess = this.checkAccess(this.accessRight);
      // call updateView method when the access was changed only
      // it's important to prevent creating element duplicates by the method createEmbeddedView
      if (previousAccess !== currentAccess) {
        this.updateView(currentAccess);
      }
    });
  }

  protected updateView(hasAccess: boolean): void {
    if (hasAccess) {
      // clear view before creating (important for dynamic additional condition in multiple access directive)
      this.viewContainer.clear();
      // create element
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // hide the element
      this.viewContainer.clear();
    }
  }

  protected checkAccess(accessRight: AccessRight): boolean {
    if (!this.accessRights) {
      return false;
    }
    // remove exclamation mark '!' from the accessRight before checking the rights
    const hasAccess = this.accessRights[('' + accessRight).replace(AccessRightOperator.negation, '')];
    // if the accessRight contains exclamation mark '!', the condition should be negated
    return ('' + accessRight).startsWith(AccessRightOperator.negation) ? !hasAccess : hasAccess;
  }
}
