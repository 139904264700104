import { Component, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, Input, OnInit } from '@angular/core';
import { ExnatonService } from '../exnaton.service';
import { ExnatonMoleculeField, ExnatonMoleculeType } from './molecule-type.enum';
import { catchError, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface TitleHelper {
  content: string;
}

interface MissingData {
  titleHelper: TitleHelper;
}

interface Production {
  production: boolean;
}

export interface TipItem {
  title: string;
  content: string;
}

@Component({
  selector: 'app-exnaton-molecule',
  templateUrl: './exnaton-molecule.component.html',
  styleUrls: ['./exnaton-molecule.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ExnatonMoleculeComponent implements OnInit {
  @Input({ required: true }) exnatonType: ExnatonMoleculeType;
  @Input() exnatonField: ExnatonMoleculeField;
  @Input() disableBorder = false;

  @Input() set showProduction(showProduction: boolean) {
    this.production = { production: showProduction };
  }
  @Input() set tooltip(tooltip: string) {
    this.titleHelper = { content: tooltip };
    this.missingData = { titleHelper: this.titleHelper };
  }
  @Input() set tipItems(tipItems: TipItem[]) {
    this.items = tipItems;
  }
  @Input() setUpErrorCalback = false;
  configLoaded: Boolean = null;
  scriptLoaded: boolean | null = null; // null = init state, true = loaded, false = error
  titleHelper: TitleHelper;
  missingData: MissingData;
  production: Production;
  items: TipItem[];

  exnatonMoleculeType = ExnatonMoleculeType;
  exnatonMoleculeField = ExnatonMoleculeField;

  constructor(private exnatonService: ExnatonService, private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.exnatonService.exnatonConfigLoaded$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((loaded: boolean) => {
      this.configLoaded = loaded;
    });
    this.exnatonService.scriptLoaded$.pipe(catchError(() => of(false))).subscribe((isScriptLoaded: boolean | null) => {
      this.scriptLoaded = isScriptLoaded || false;
    });
  }
}
