<div [ngSwitch]="exnatonType" *ngIf="scriptLoaded && configLoaded" [ngClass]="{'md:h-full border border-solid border-gray-300 bg-white rounded-4xl' : !disableBorder}">
  <div *ngSwitchCase="exnatonMoleculeType.datePicker">
    <ng-container [ngTemplateOutlet]="exnatonDatePicker"></ng-container>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.energyChart">
    <exnaton-energy-chart [component]="{ configs: production, titleHelper }"></exnaton-energy-chart>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.financeChart">
    <exnaton-finance-chart [component]="{ configs: production, titleHelper }"></exnaton-finance-chart>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.priceChart">
    <exnaton-price-chart
      *ngIf="setUpErrorCalback; else noErrorCallback"
      [component]="{ withDirective: false, titleHelper }"
      onerror="errorCallbackCurrentPrice"
    ></exnaton-price-chart>
    <ng-template #noErrorCallback>
      <exnaton-price-chart [component]="{ withDirective: false, titleHelper }"></exnaton-price-chart>
    </ng-template>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.currentPrice">
    <exnaton-current-price [component]="{ continuous: false, titleHelper }"></exnaton-current-price>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.analyticsView">
    <exnaton-analytics-view [component]="{ titleHelper }"></exnaton-analytics-view>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.comparison">
    <exnaton-comparison [component]="{ titleHelper }"></exnaton-comparison>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.productView">
    <exnaton-product-view [component]="{ titleHelper }"></exnaton-product-view>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.kpiCard">
    <div [ngSwitch]="exnatonField">
      <div *ngSwitchCase="exnatonMoleculeField.accountTotalPriceForConsumption">
        <exnaton-kpi-card
          [component]="{ field: exnatonMoleculeField.accountTotalPriceForConsumption, titleHelper, missingData }"
        ></exnaton-kpi-card>
      </div>
      <div *ngSwitchCase="exnatonMoleculeField.accountAveragePriceForConsumption">
        <exnaton-kpi-card
          [component]="{ field: exnatonMoleculeField.accountAveragePriceForConsumption, titleHelper, missingData }"
        ></exnaton-kpi-card>
      </div>
      <div *ngSwitchCase="exnatonMoleculeField.accountTotalEnergyProduction">
        <exnaton-kpi-card
          [component]="{ field: exnatonMoleculeField.accountTotalEnergyProduction, titleHelper, missingData }"
        ></exnaton-kpi-card>
      </div>
      <div *ngSwitchCase="exnatonMoleculeField.accountTotalPriceForProduction">
        <exnaton-kpi-card
          [component]="{ field: exnatonMoleculeField.accountTotalPriceForProduction, titleHelper, missingData }"
        ></exnaton-kpi-card>
      </div>
      <div *ngSwitchDefault>
        <exnaton-kpi-card [component]="{ titleHelper, missingData }"></exnaton-kpi-card>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.selfConsumptionChart">
    <exnaton-self-consumption-chart [component]="{ titleHelper }"></exnaton-self-consumption-chart>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.carouselCard">
    <exnaton-carousel-card [component]="{ items }"></exnaton-carousel-card>
  </div>
  <div *ngSwitchCase="exnatonMoleculeType.detailedReport">
    <ng-content></ng-content>
    <div class="m-8 max-w-[30rem]">
      <exnaton-detailed-report></exnaton-detailed-report>
    </div>
  </div>
</div>

<div *ngIf="scriptLoaded === false">
  <div class="relative rounded-2xl w-full h-full z-10 flex items-center border-grey">
    <div class="bg-white p-8 text-primary rounded-2xl">
      <img src="./assets/img/error-red.svg" class="inline-block mr-4 w-6" />
      <span>{{ 'components.dynamicTariff.error' | translate }}</span>
    </div>
  </div>
</div>

<ng-template #exnatonDatePicker>
  <exnaton-date-picker
    component='{"withHeader": true}'
    theme='{"primary":"#2450FF","defaultRadius":"$sm"}'
  ></exnaton-date-picker>
</ng-template>
