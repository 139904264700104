import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentPreferencesComponent } from './pages/payment-preferences/payment-preferences.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { DirectDebitComponent } from './components/payment-method/components/direct-debit/direct-debit.component';
import { BankTransferComponent } from './components/payment-method/components/bank-transfer/bank-transfer.component';
import { InvoiceDeliveryChannelComponent } from './components/invoice-delivery-channel/invoice-delivery-channel.component';
import { EmailComponent } from './components/invoice-delivery-channel/components/email/email.component';
import { PaperComponent } from './components/invoice-delivery-channel/components/paper/paper.component';
import { ZoomitComponent } from './components/invoice-delivery-channel/components/zoomit/zoomit.component';
import { ContactModule } from '../contact/contact.module';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BankDetailsDisplayComponent } from './components/bank-details-display/bank-details-display.component';
// eslint-disable-next-line max-len
import { ConfirmationEmailSentComponent } from './components/payment-method/components/change-payment-method/components/confirmation-email-sent/confirmation-email-sent.component';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { RefundMethodComponent } from './components/refund-method/refund-method.component';
// eslint-disable-next-line max-len
import { ChangePaymentMethodComponent } from './components/payment-method/components/change-payment-method/change-payment-method.component';
import { ChangeRefundAccountComponent } from './components/refund-method/components/change-refund-account/change-refund-account.component';
// eslint-disable-next-line max-len
import { ChangeInvoiceMethodComponent } from './components/invoice-delivery-channel/components/change-invoice-method/change-invoice-method.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ErrorAccessComponent } from '../../../shared/components/error-access/error-access.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ContactModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    AnalyticsModule,
    ErrorAccessComponent,
  ],
  declarations: [
    PaymentPreferencesComponent,
    PaymentMethodComponent,
    DirectDebitComponent,
    BankTransferComponent,
    InvoiceDeliveryChannelComponent,
    EmailComponent,
    PaperComponent,
    ZoomitComponent,
    BankDetailsDisplayComponent,
    ConfirmationEmailSentComponent,
    RefundMethodComponent,
    ChangePaymentMethodComponent,
    ChangeRefundAccountComponent,
    ChangeInvoiceMethodComponent,
  ],
  exports: [BankDetailsDisplayComponent],
})
export class BillingModule {}

_('pages.advancesPayment.pageTitle');
_('pages.paymentPreferences.pageTitle');
_('components.invoiceDeliveryChannel.switchToPaper.reason.forget_to_pay');
_('components.invoiceDeliveryChannel.switchToPaper.reason.others');
_('components.invoiceDeliveryChannel.switchToPaper.reason.technical_issues');
_('components.paymentMethod.paymentMethodChangeInError');

_('general.billing.BANK_TRANSFER');
_('general.billing.BANK_DOMICILIATION');
_('general.billing.DIRECT_DEBIT');
_('general.billing.DIRECT_DEBIT_AND_BANK_TRANSFER');
