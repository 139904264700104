import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { TranslateModule } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface ErrorAccessConfig {
  title: string;
  content: string;
  ctaLink: string;
  ctaLabel: string;
  navigateExternalSite: boolean;
}

@Component({
  imports: [CommonModule, TranslateModule],
  selector: 'app-error-access',
  templateUrl: './error-access.component.html',
  standalone: true,
})
export class ErrorAccessComponent implements OnInit {
  @Input() config?: ErrorAccessConfig = {
    title: 'components.pageError.billingAccess.title',
    content: 'components.pageError.billingAccess.description',
    ctaLink: '/invoices/advance-payment',
    ctaLabel: 'components.pageError.billingAccess.back',
    navigateExternalSite: false,
  };
  constructor(private facade: MainFacade) {}

  ngOnInit(): void {}

  backToConsumptions() {
    if (this.config.navigateExternalSite) {
      window.open(this.facade.translate.instant(this.config.ctaLink), '_self');
    } else {
      this.facade.utils.redirectTo(this.facade.translate.instant(this.config.ctaLink));
    }
  }
}

_('components.pageError.billingAccess.title');
_('components.pageError.billingAccess.description');
_('components.pageError.billingAccess.back');
