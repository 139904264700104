import {
  AccessRights,
  EliqAccessRights,
  MyEnergyActionsPerEAN,
} from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { Content } from '@app/shared/models/content.interface';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { InvoiceResponseV2CuzoApi, OpenInvoicesCuzoApi, PaymentPlanCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { ApiResponse } from '@app/shared/models/api.inteface';

export interface State {
  readonly accessRights?: AccessRights;
  readonly computedData?: ComputedData;
  readonly reference?: string;
  readonly activeSite?: Site;
  readonly sites?: Site[];
  readonly contracts?: ContractDtoCuzoApi[];
  readonly content?: Content;
  readonly myEnergyActionsPerEAN?: MyEnergyActionsPerEAN;
  readonly eliqAccessRights?: EliqAccessRights;
  readonly accessDynamicTariffPerRef?: boolean; // At least one eans in site has access to dynamic tariff
}

export const INITIAL_STATE: State = {
  accessRights: null,
  computedData: null,
  reference: null,
  activeSite: null,
  sites: [],
  contracts: [],
  content: null,
  myEnergyActionsPerEAN: null,
  eliqAccessRights: null,
  accessDynamicTariffPerRef: false,
};

export interface ComputedData {
  isSocial?: boolean;
  hasSolarPanel?: boolean;
  hasChargingStation?: boolean;
}

export const INITIAL_BILLING_STATE: BillingState = {
  openInvoices: null,
  paymentPlan: null,
  invoices: null,
  payWithDirectDebit: null,
};

export interface InvoiceFilters {
  invoiceYears: number[];
  invoiceTypes: string[];
}

export interface BillingState {
  readonly openInvoices: ApiResponse<OpenInvoicesCuzoApi>;
  readonly paymentPlan: ApiResponse<PaymentPlanCuzoApi>;
  readonly invoices: ApiResponse<InvoiceResponseV2CuzoApi>;
  readonly payWithDirectDebit: boolean;
}
