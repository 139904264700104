import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { FormControl, FormControlStatus, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ORDERED_QUESTION_LEAVE,
  ORDERED_QUESTION_STAY,
} from '@app/modules/customer-zone/move/components/move-form/move-form-config';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MainFacade } from '@app/core/facade/main.facade';
import { filter, Observable, Subject, take, takeUntil } from 'rxjs';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { INITIAL_MOVE_STATE, MoveDTO, MoveState } from '@app/core/state/move.state';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import { TranslateModule } from '@ngx-translate/core';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-supplier',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, NavigationComponent, ReactiveFormsModule, TranslateModule, AlertComponent],
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss'],
})
export class SupplierComponent extends MoveFormStep<MoveDTO> implements OnInit, OnDestroy {
  formControl: FormControl<boolean> = new FormControl(null, Validators.required);
  formIsValid = false;
  isFormDisabled = false;
  notifier: Subject<void> = new Subject<void>();
  private moveDTO: MoveDTO;
  readonly AlertType = AlertType;

  constructor(private readonly facade: MainFacade, protected readonly moveFormFacade: MoveFormFacade) {
    super(moveFormFacade);
  }

  ngOnInit(): void {
    this.formControl.statusChanges.pipe(takeUntil(this.notifier)).subscribe((value: FormControlStatus) => {
      // Standard valid prop here is not enough, we need to check if the form is valid or disabled
      // When all formControls in formGroup (or one as in this case) are disabled then status is DISABLED too, which set up invalid and valid props to false
      // We can't use readonly prop too, because it is input type radio
      this.formIsValid = value === 'VALID' || (value === 'DISABLED' && this.formControl.valid === false);
    });
    this.moveFormFacade.state$
      .pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        takeUntil(this.notifier)
      )
      .subscribe((state: MoveState) => {
        this.moveDTO = state?.moveDTO;
        this.formControl.setValue(state?.form?.supplier?.amendmentField);
        // Customer can't change if he is staying in the TE or not after he submitted this step
        this.isFormDisabled = this.moveDTO.id && state.moveDTO.amendmentField !== null && state?.currentStep === 0;
        if (this.isFormDisabled) {
          this.formControl.disable();
        }
      });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  onNextClickedDefault() {
    if (this.formIsValid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      const operation$: Observable<MoveDTO> = this.saveFormData();
      operation$.pipe(take(1)).subscribe((): void => {
        this.moveFormFacade.next();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
    } else {
      this.formControl.markAsDirty();
    }
  }

  onPreviousClickedDefault() {
    this.moveFormFacade.previous();
  }

  saveFormData(): Observable<MoveDTO> {
    const hasMoveId: string = this.moveDTO?.id;
    const customerReference: string = this.moveDTO?.customerReference || this.facade.state$.value.reference;
    const moveState = this.moveFormFacade.state$.value;

    this.moveFormFacade.updateData({
      moveDTO: {
        ...this.moveDTO,
        customerReference,
        amendmentField: this.formControl.value,
      },
      form: {
        supplier: {
          amendmentField: this.formControl.value,
        },
        ...moveState.form,
      },
    });

    this.moveFormFacade.setRoute(this.formControl.value ? ORDERED_QUESTION_STAY : ORDERED_QUESTION_LEAVE);

    return hasMoveId ? this.moveFormFacade.updateMoveOut() : this.moveFormFacade.create(customerReference);
  }
}

_('pages.move.supplier.savedChoice');
_('errorMessages.move.requiredValuesMissing');
