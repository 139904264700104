import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BillingFacade } from '@app/core/facade/billing.facade';
import { filter, Observable, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import {
  invoiceAlerts,
  InvoiceAlerts,
  InvoiceParams,
} from '@app/modules/customer-zone/invoices/models/invoice.interface';
import { AnalyticsModule } from '@app/shared/modules/analytics/analytics.module';
import { InvoiceComponent } from '@app/modules/customer-zone/invoices/components/invoice/invoice.component';
import { InvoicesModule } from '@app/modules/customer-zone/invoices/invoices.module';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { FaqModule } from '@app/shared/modules/faq/faq.module';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { ApiResponse } from '@app/shared/models/api.inteface';
import { InvoiceResponseV2CuzoApi, ProductCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { ApplyFilters } from '../../components/filters/filters.component';
import { InvoiceFilters } from '@app/core/state/state';
import { GhostTableComponent } from '@app/shared/components/ghost-table/ghost-table.component';
import { RouterLink } from '@angular/router';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { Product } from '@app/modules/customer-zone/user/models/product.interface';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AnalyticsModule,
    NgOptimizedImage,
    InvoiceComponent,
    InvoicesModule,
    NgbPagination,
    FaqModule,
    AlertComponent,
    GhostTableComponent,
    RouterLink,
  ],
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit, OnDestroy {
  readonly invoiceAlerts: InvoiceAlerts = invoiceAlerts;
  readonly invoicesPerPage: number = this.billingFacade.invoicesPerPage;
  invoiceFilters: InvoiceFilters = null;
  invoices$: Observable<ApiResponse<InvoiceResponseV2CuzoApi>> = this.billingFacade.invoices$.pipe(
    tap((invoices: ApiResponse<InvoiceResponseV2CuzoApi>): void => this.resetInvoiceFiltersIfReferenceChanged(invoices))
  );
  toggleFilters: boolean = false;
  activeFilters: InvoiceParams;
  activePage: number = 1;
  subscription: Subscription;
  reference: string;
  notifier = new Subject<void>();
  accessDynamicTariff: boolean = false;

  constructor(private facade: MainFacade, private billingFacade: BillingFacade) {}

  ngOnInit(): void {
    this.subscription = this.billingFacade.loadInvoices();
    this.billingFacade.isRedirectedFromPayNxt();

    this.facade.activeSite$
      .pipe(
        filter((site: Site) => !!site),
        takeUntil(this.notifier)
      )
      .subscribe((site: Site) => {
        site.products.forEach((product: Product) => {
          if (
            this.facade.state$.value.accessRights?.sites?.[site.id]?.eans?.[product.ean]?.accessDynamicTariffFeatures
          ) {
            this.accessDynamicTariff = true;
          }
        });
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateFilters($event: ApplyFilters): void {
    if ($event?.filters) {
      const { filters } = $event;
      const invoiceParams: InvoiceParams = this.getInvoicesParams(0, filters);
      this.billingFacade.updateParams(invoiceParams);
      this.activeFilters = filters;
      this.activePage = 1;
    }
  }

  getInvoices(page: number): void {
    const offset: number = (page - 1) * this.invoicesPerPage;
    const invoiceParams: InvoiceParams = this.getInvoicesParams(offset);
    this.billingFacade.updateParams(invoiceParams);
    this.scrollToTop();
  }

  private scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  private getInvoicesParams(offset: number, filters: Partial<InvoiceParams> = this.activeFilters): InvoiceParams {
    return {
      offset,
      ...filters,
      limit: this.invoicesPerPage,
    };
  }

  private resetFilters(invoiceFilters: InvoiceFilters, reference: string): void {
    this.reference = reference;
    this.invoiceFilters = invoiceFilters;
    this.activeFilters = null;
    this.activePage = 1;
  }

  private resetInvoiceFiltersIfReferenceChanged(invoices: ApiResponse<InvoiceResponseV2CuzoApi>): void {
    const invoiceFilters: InvoiceFilters = {
      invoiceYears: invoices?.data?.invoiceYears,
      invoiceTypes: invoices?.data?.invoiceTypes,
    };
    if (this.reference !== invoices?.data?.reference) {
      this.resetFilters(invoiceFilters, invoices?.data?.reference);
    }
  }
}
