import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '@app/shared/utils/app-config';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { ScriptLoaderService } from './script-loader.service';
import { BehaviorSubject, from, Observable, take } from 'rxjs';
import { MainFacade } from '@app/core/facade/main.facade';
import { ContractDtoCuzoApi, ContractDtoTypeEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';

interface Exnaton {
  api: {
    base: string;
    credentials: string;
    headers: {
      authorization: string;
    };
  };
  context: {
    accountRef: string;
  };
  theme: {
    textPrimary: string;
    primary: string;
    defaultShadow: number | string;
    defaultRadius: string;
    font: {};
    components: {};
  };
  locale: string;
  setConfig: (exnaton: Partial<Exnaton>) => Exnaton;
}

interface WindowExnaton extends Window {
  Exnaton?: Exnaton;
  errorCallbackCurrentPrice?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ExnatonService {
  private window: WindowExnaton;
  private activeReference: string;
  private activeSiteId: string;
  private activeDeliveryPoint: string;
  private _scriptLoaded$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  private exnatonConfigLoaded = new BehaviorSubject(false);
  private alreadyRedirectToNoPricePage = false;

  get scriptLoaded$(): Observable<boolean | null> {
    return this._scriptLoaded$.asObservable();
  }

  get exnatonConfigLoaded$(): Observable<boolean> {
    return this.exnatonConfigLoaded.asObservable();
  }

  get alreadyRedirected(): boolean {
    return this.alreadyRedirectToNoPricePage;
  }

  set alreadyRedirected(value: boolean) {
    this.alreadyRedirectToNoPricePage = value;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appConfig: AppConfig,
    private keycloakService: KeycloakService,
    private translateService: TranslateService,
    private facade: MainFacade,
    private scriptLoaderService: ScriptLoaderService
  ) {
    this.window = <Window>this.document.defaultView;
    this.loadAndSetConfig();
    this.createCallbackInWindow();
  }

  updateExnatonConfig(siteId: string): void {
    this.activeReference = this.facade.state$.value.reference;
    this.activeSiteId = siteId;
    this.exnatonConfigLoaded.next(false);
    this.facade.contracts$.pipe(take(1)).subscribe((contracts: ContractDtoCuzoApi[]) => {
      const contract = contracts.find(
        (contract: ContractDtoCuzoApi) => contract.type === ContractDtoTypeEnumCuzoApi.ELECTRICITY
      );
      this.activeDeliveryPoint = contract.deliveryPointReference;

      if (!this._scriptLoaded$.value) {
        this._scriptLoaded$.next(null);
        this.loadAndSetConfig();
      } else {
        this.applyExnatonConfig();
        this.exnatonConfigLoaded.next(true);
      }
    });
  }

  createCallbackInWindow(): void {
    this.window.errorCallbackCurrentPrice = this.errorCallbackCurrentPrice.bind(this);
  }

  errorCallbackCurrentPrice(): void {
    this.facade.utils.redirectTo('/consumptions/error-exnaton');
  }

  private applyExnatonConfig(): void {
    const windowExnatonObject = this.window.Exnaton;
    const authToken = this.keycloakService.getKeycloakInstance().token;
    const baseUrl = `${this.appConfig.config.api.serverHost + this.appConfig.config.api.baseHref}/v1/dynamic-tariff`;

    if (windowExnatonObject && this.activeSiteId && this.activeDeliveryPoint) {
      windowExnatonObject.setConfig({
        locale: `${this.translateService.currentLang}-BE`,
        context: { accountRef: '__externalSubjectReference__' },
        api: {
          base: `${baseUrl}/${this.activeReference}/sites/${this.activeSiteId}/delivery-points/${this.activeDeliveryPoint}`,
          credentials: 'omit',
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        },
        theme: {
          textPrimary: '#374649',
          primary: '#3F53FA',
          defaultShadow: 0,
          defaultRadius: '$lg',
          font: {
            heading: { family: 'Roboto', fontWeight: 500, fontSize: 16 },
          },
          components: {
            TitleHelperInfo: {
              iconProps: {
                backgroundColor: '#ffffff',
                color: '#B7CBD399',
                size: 18,
                iconSize: 28,
                icon: 'circle-question',
              },
            },
            Dropdown: {
              withAdapt: false,
            },
            KPICard: { withThemeIcon: false },
          },
        },
      });
    }
  }

  private loadAndSetConfig(): Observable<boolean | null> {
    return from(
      this.scriptLoaderService.loadScript('https://cdn.exnaton.net/js/molecules/latest/web-components.mjs').then(
        () => {
          if (this.window.Exnaton) {
            this._scriptLoaded$.next(true);
            this.applyExnatonConfig();
            this.exnatonConfigLoaded.next(true);

            return true;
          }
        },
        (error) => {
          console.log('Error loading exnaton script', error);
          this._scriptLoaded$.next(false);
          const currentUrl = this.facade.proxyRouter.url;
          const consumptionUrl = '/consumptions';
          if (currentUrl.includes(consumptionUrl)) {
            this.facade.utils.redirectTo('/consumptions/error-exnaton');
          }
          throw new Error('exnaton script not loaded');
        }
      )
    );
  }
}
