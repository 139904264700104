import { Component, Input, OnInit } from '@angular/core';
import { ContractDetailComponent } from 'src/app/modules/customer-zone/contracts/components/modals/contract-detail/contract-detail.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Site } from 'src/app/modules/customer-zone/user/models/site.interface';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AccessRight } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { AsyncData } from '../../../../../shared/models/async.interface';
import {
  ContractDtoCuzoApi,
  ContractDtoTypeEnumCuzoApi,
  TariffCardCuzoApi,
  TariffCardTypeEnumCuzoApi,
} from '@app/shared/models/cuzo-be-contract';

export const compensatedOfftake = 'COMPENSATED_OFFTAKE';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
})
export class ContractComponent implements OnInit {
  @Input() public contract: ContractDtoCuzoApi;
  @Input() public site: Site;
  tariffCards: TariffCardCuzoApi[];
  readonly accessRight = AccessRight;

  constructor(private modalService: NgbModal, public translate: TranslateService) {}

  ngOnInit(): void {
    this.tariffCards = this.contract?.tariffCards.filter((tariffCard) => {
      return this.contract.deliveryPointComponent === compensatedOfftake
        ? tariffCard.type !== TariffCardTypeEnumCuzoApi.INJECTION
        : true;
    });
  }

  isProductGasOrElec() {
    return (
      ContractDtoTypeEnumCuzoApi[ContractDtoTypeEnumCuzoApi.GAS] === this.contract.type ||
      ContractDtoTypeEnumCuzoApi[ContractDtoTypeEnumCuzoApi.ELECTRICITY] === this.contract.type
    );
  }

  openContractDetail() {
    this.openModal();
  }

  public openModal() {
    const asyncContract: AsyncData<ContractDtoCuzoApi> = { loading: false, data: this.contract };
    const modalRef = this.modalService.open(ContractDetailComponent, {
      size: 'lg',
    });
    const data = { contract$: of(asyncContract), site$: of(this.site) };
    modalRef.componentInstance.data = data;
  }
}
