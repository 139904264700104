<div class="pb-10 z-20 relative">
  <h2 class="text-2xl font-sans text-grey-dark60 font-medium mb-8">
    {{ 'pages.dashboard.dynamicTariff.title' | translate }}
  </h2>
  <section
    id="dynamic-tariff"
    class="grid md:grid-cols-4 lg:grid-cols-3 gap-10"
    *ngIf="(scriptLoaded$ | async) !== null; else loading"
  >
    <div class="md:col-span-2 lg:col-span-1">
      <app-exnaton-molecule
        [exnatonType]="exnatonMoleculeType.currentPrice"
        [tooltip]="'pages.dynamicTariff.tooltip.currentPrice' | translate"
      ></app-exnaton-molecule>
    </div>
    <div class="md:col-span-2">
      <app-exnaton-molecule
        [exnatonType]="exnatonMoleculeType.priceChart"
        [tooltip]="'pages.dynamicTariff.tooltip.priceChart' | translate"
      ></app-exnaton-molecule>
    </div>
  </section>
</div>

<ng-template #loading>
  <app-loader-widget [showBorder]="false"></app-loader-widget>
</ng-template>
