import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ExnatonMoleculeComponent } from '@app/modules/customer-zone/exnaton/exnaton-molecule/exnaton-molecule.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ExnatonMoleculeType } from '@app/modules/customer-zone/exnaton/exnaton-molecule/molecule-type.enum';
import { DynamicTariffService } from '@app/modules/customer-zone/consumption/pages/dynamic-tariff/dynamic-tariff.service';
import { LoaderWidgetComponent } from '@app/modules/customer-zone/consumption/pages/dashboard/widgets/loader-widget/loader-widget.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-tariff-preview',
  templateUrl: './dynamic-tariff-preview.component.html',
  styleUrls: ['./dynamic-tariff-preview.component.scss'],
  standalone: true,
  imports: [TranslateModule, ExnatonMoleculeComponent, NgIf, AsyncPipe, LoaderWidgetComponent],
  providers: [DynamicTariffService],
})
export class DynamicTariffPreviewComponent implements OnInit {
  exnatonMoleculeType = ExnatonMoleculeType;
  scriptLoaded$: Observable<boolean>;

  constructor(private readonly dynamicTariffFacade: DynamicTariffService) {
    this.scriptLoaded$ = this.dynamicTariffFacade.scriptLoaded$;
    this.dynamicTariffFacade.updateConfigAfterRefAndLangChanges();
  }

  ngOnInit(): void {}
}
