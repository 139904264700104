import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TevcMonitoringService } from '../../services/tevc-monitoring.service';
import { Observable } from 'rxjs/internal/Observable';
import { filter } from 'rxjs';
import { ChargingStation, ChargingStationSessionReport } from '../../models/charging-station.interface';
import moment from 'moment';
import { takeUntil } from 'rxjs';
import { Granularity, SwitchOption } from '../../models/tevc.constants';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  public chargingStations$: BehaviorSubject<ChargingStation[]> =
    this.monitoringService.getChargingStations$() as BehaviorSubject<ChargingStation[]>;
  public chargingStation$: Observable<ChargingStation> = this.monitoringService.getChargingStation$();
  public chargingStationSessionReports$: Observable<ChargingStationSessionReport[]> =
    this.monitoringService.getChargingStationSessionReports$();

  chargingStationOptions: { [key: string]: string } = {};
  granularityOptions: { [key: string]: string } = {
    [Granularity.year]: this.facade.translate.instant('label.year'),
    [Granularity.months12]: this.facade.translate.instant('label.12Months'),
    [Granularity.months24]: this.facade.translate.instant('label.24Months'),
  };

  activeGranularityFilter: Granularity = Granularity.year;
  currentYear: string = moment().year().toString();
  activeYear: string = moment().year().toString();
  granularity = Granularity;
  activeSwitchOption: SwitchOption = SwitchOption.energy;
  objectKeys = Object.keys;

  private reference: string;
  private notifier$: Subject<void> = new Subject();
  private chargingStationName: string;

  constructor(private facade: MainFacade, private monitoringService: TevcMonitoringService) {}

  ngOnInit(): void {
    this.analytics();
    this.facade.utils.setPageTitle('pages.tevcMonitoring.monthlyReport.pageTitle');
    this.facade.reference$.pipe(takeUntil(this.notifier$)).subscribe((reference: string) => {
      this.reference = reference;
      this.monitoringService.getChargingStations(reference);
    });

    this.chargingStations$
      .pipe(
        filter((value) => value !== null),
        takeUntil(this.notifier$)
      )
      .subscribe((chargingStations) => {
        this.chargingStationOptions = {};
        chargingStations.forEach((station) => {
          this.chargingStationOptions[station.name] = this.facade.translate.instant('label.name {{name}}', {
            name: station.name,
          });
        });
      });

    // Update the filters label when we switch language.
    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier$)).subscribe((value) => {
      if (!this.chargingStations$.value) {
        return;
      }
      this.chargingStationOptions = {};
      this.chargingStations$.value.forEach((station) => {
        this.chargingStationOptions[station.name] = this.facade.translate.instant('label.name {{name}}', {
          name: station.name,
        });
      });
      this.granularityOptions = {
        [Granularity.year]: this.facade.translate.instant('label.year'),
        [Granularity.months12]: this.facade.translate.instant('label.12Months'),
        [Granularity.months24]: this.facade.translate.instant('label.24Months'),
      };
    });

    this.chargingStation$
      .pipe(
        filter((value) => value !== null),
        takeUntil(this.notifier$)
      )
      .subscribe((value) => {
        this.chargingStationName = value.name;
        this.activeYear = moment().year().toString();
        const fromDate = moment().startOf('year').format('YYYY-MM-DD');
        const toDate = moment().endOf('year').add(1, 'day').format('YYYY-MM-DD');
        this.monitoringService.getChargingStationSessionReports(
          this.reference,
          value.name,
          'monthly',
          fromDate,
          toDate
        );
      });
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  applyGranularityFilter(value: Granularity) {
    let toDate;
    let fromDate;
    switch (value) {
      case Granularity.months12:
        toDate = moment().endOf('month').add(1, 'day').format('YYYY-MM-DD');
        fromDate = moment().endOf('month').subtract(12, 'months').format('YYYY-MM-DD');
        break;

      case Granularity.months24:
        toDate = moment().endOf('month').add(1, 'day').format('YYYY-MM-DD');
        fromDate = moment().endOf('month').subtract(24, 'months').format('YYYY-MM-DD');
        break;

      // When we switch to year filter we always start from the current year.
      case Granularity.year:
      default:
        this.activeYear = moment().year().toString();
        fromDate = moment().startOf('year').format('YYYY-MM-DD');
        toDate = moment().add(1, 'day').format('YYYY-MM-DD');
    }

    this.activeGranularityFilter = value;
    this.monitoringService.getChargingStationSessionReports(
      this.reference,
      this.chargingStationName,
      'monthly',
      fromDate,
      toDate
    );
  }

  changeSwitchOption(option: SwitchOption): void {
    this.activeSwitchOption = option;
  }

  applyChargingStationFilter(name: string) {
    this.monitoringService.getChargingStation(name);
  }

  navigateBetweenYears(value: number) {
    this.activeYear = moment(this.activeYear).add(value, 'years').year().toString();
    const fromDate = moment(this.activeYear).startOf('year').format('YYYY-MM-DD');
    const toDate = moment(this.activeYear).endOf('year').add(1, 'day').format('YYYY-MM-DD');
    this.monitoringService.getChargingStationSessionReports(
      this.reference,
      this.chargingStationName,
      'monthly',
      fromDate,
      toDate
    );
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'tevc monitoring reports - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
