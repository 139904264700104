import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDatepicker,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbDropdown,
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { DatepickerI18n } from '../../services/datepicker-i18n';
import { Observable } from 'rxjs/internal/Observable';
import { filter } from 'rxjs';

// Custom date picker. When user selects a date, the whole week will be selected.
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: DatepickerI18n }],
})
export class DatePickerComponent implements OnInit, OnDestroy {
  @ViewChild('datepicker') ngbDatepicker: NgbDatepicker;
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @Input() activeDateRange$: Observable<{ dateFrom: Date; dateTo: Date }>;
  @Output() rangeSelected: EventEmitter<{ selectedDateFrom: Date; selectedDateTo: Date }> = new EventEmitter<{
    selectedDateFrom: Date;
    selectedDateTo: Date;
  }>();
  maxDate: NgbDateStruct = this.calendar.getToday();
  hoveredDate: NgbDate;
  yearsAvailable: number[] = [];
  selectedYear = this.calendar.getToday().year;
  selectedDate: NgbDate;
  selectedDateFrom: Date;
  selectedDateTo: Date;

  currentMonth: number;
  currentYear: number;

  yearSelectIsShowing = false;
  monthSelectIsShowing = false;

  private clickInsideComponent = false;

  constructor(public calendar: NgbCalendar, public i18n: NgbDatepickerI18n) {
    // last 5 years are available to select
    for (let i = 0; i < 5; i++) {
      this.yearsAvailable.push(this.calendar.getToday().year - i);
    }
  }

  @HostListener('click', ['$event'])
  clickInside(event: any) {
    this.clickInsideComponent = event.target.parentNode.id !== 'date-picker-dropdown';
  }

  @HostListener('document:keydown.escape')
  @HostListener('document:click')
  clickAnywhere() {
    if (!this.clickInsideComponent) {
      this.monthSelectIsShowing = false;
      this.yearSelectIsShowing = false;
    }
    this.clickInsideComponent = false;
  }

  ngOnInit(): void {
    // we use currentMonth and currentYear for the correct display of the 'next' arrow (navigation between months)
    this.currentMonth = moment().month() + 1;
    this.currentYear = moment().year();

    this.activeDateRange$.pipe(filter((date) => date != null)).subscribe(({ dateFrom, dateTo }) => {
      this.selectedDateFrom = dateFrom;
      this.selectedDate = new NgbDate(
        this.selectedDateFrom.getFullYear(),
        this.selectedDateFrom.getMonth() + 1,
        this.selectedDateFrom.getDay()
      );
      this.selectedDateTo = dateTo;
    });
  }
  ngOnDestroy(): void {}

  onDateSelection(date: NgbDate): void {
    this.selectedDate = date;
    // when selecting a date, the whole week is selected
    this.selectedDateFrom = moment(this.createDateFromNgbDate(date)).startOf('isoWeek').toDate();
    this.selectedDateTo = moment(this.createDateFromNgbDate(date)).endOf('isoWeek').toDate();
    this.rangeSelected.emit({
      selectedDateFrom: this.selectedDateFrom,
      selectedDateTo: this.selectedDateTo,
    });
  }

  navigate(year: number, month: number): void {
    this.ngbDatepicker.navigateTo({ year, month });
  }

  navigateBetweenMonths(month: number): void {
    this.ngbDatepicker.navigateTo(this.calendar.getNext(this.ngbDatepicker.state.focusedDate, 'm', month));
  }

  isDateInCalendarSelected(date: NgbDate): boolean {
    // if a user clicks on a day (or hovers over the day) the full week will be selected
    if (this.hoveredDate) {
      const startOfWeek = moment(this.createDateFromNgbDate(this.hoveredDate)).startOf('isoWeek').toDate();
      const endOfWeek = moment(this.createDateFromNgbDate(this.hoveredDate)).endOf('isoWeek').toDate();
      return (
        (this.createDateFromNgbDate(date) >= startOfWeek && this.createDateFromNgbDate(date) <= endOfWeek) ||
        (this.createDateFromNgbDate(date) >= this.selectedDateFrom &&
          this.createDateFromNgbDate(date) <= this.selectedDateTo)
      );
    } else {
      return (
        this.createDateFromNgbDate(date) >= this.selectedDateFrom &&
        this.createDateFromNgbDate(date) <= this.selectedDateTo
      );
    }
  }

  isDateInCalendarCurrentMonth(date: NgbDate): boolean {
    // only dates in displayed month are highlighted (when we display current month, only days until today are highlighted)
    const startOfMonth = moment(
      this.createDateFromNgbDate(
        this.ngbDatepicker?.state.focusedDate ? this.ngbDatepicker?.state.focusedDate : this.selectedDate
      )
    )
      .startOf('month')
      .toDate();
    const endOfMonth = moment(
      this.createDateFromNgbDate(
        this.ngbDatepicker?.state.focusedDate ? this.ngbDatepicker?.state.focusedDate : this.selectedDate
      )
    )
      .endOf('month')
      .toDate();
    return (
      this.createDateFromNgbDate(date) >= startOfMonth &&
      this.createDateFromNgbDate(date) <= endOfMonth &&
      this.createDateFromNgbDate(date) <= moment().toDate()
    );
  }

  isDropdownOpen(): boolean {
    return this.dropdown.isOpen();
  }

  private createDateFromNgbDate(ngbDate: NgbDate): Date {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }
}
