import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { DashboardModule } from './modules/customer-zone/dashboard/dashboard.module';
import { CoreModule } from './core/core.module';
import { BindingProcessModule } from './modules/binding-process/binding-process.module';
import { MissingTranslationHandler, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr-BE';
import localeNl from '@angular/common/locales/nl-BE';
import { CommonModule, LowerCasePipe, registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';
import { initializer } from './shared/utils/app-init';
import { CookieService } from 'ngx-cookie-service';
import { RegistrationModule } from './modules/registration/registration.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ContractsModule } from './modules/customer-zone/contracts/contracts.module';
import { BuildingModule } from './modules/customer-zone/building/building.module';
import { UserModule } from './modules/customer-zone/user/user.module';
import { SalesforceChatModule } from '@totalenergiescode/springbox-salesforce-chat';
import { ConsumptionModule } from './modules/customer-zone/consumption/consumption.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpsellModule } from './modules/customer-zone/upsell/upsell.module';
import { LoaderModule } from './shared/modules/loader/loader.module';
import { AnalyticsModule } from './shared/modules/analytics/analytics.module';
import { AnalyticsEventProcessor } from './core/analytics/analytics-event.processor';
import { MgmModule } from './modules/customer-zone/mgm/mgm.module';
import { ClipboardModule } from 'ngx-clipboard';
import { TecvMonitoringModule } from './modules/customer-zone/tevc-monitoring/tevc-monitoring.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NgChartsModule } from 'ng2-charts';
import { IMaskModule } from 'angular-imask';
import { default as Annotation } from 'chartjs-plugin-annotation';
import { AppConfig } from './shared/utils/app-config';
import { ChartModule } from './modules/chart/chart.module';
import { MyMissingTranslationHandler } from '@app/shared/utils/missing-translation-handler';
import { DeviceService } from '@app/core/service/device.service';
import { AddDaysToDatePipe } from '@app/shared/pipes/add-days-to-date/add-days-to-date.pipe';
import { MoveFormRoutingModule } from './modules/customer-zone/move/move-form-routing.module';

registerLocaleData(localeFr);
registerLocaleData(localeNl);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    RegistrationModule,
    NgbModalModule,
    LoaderModule,
    CoreModule,
    DashboardModule,
    ContractsModule,
    ConsumptionModule,
    UserModule,
    MgmModule,
    BindingProcessModule,
    BuildingModule,
    TecvMonitoringModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    UpsellModule,
    LoaderModule,
    ClipboardModule,
    AnalyticsModule.forRoot(new AnalyticsEventProcessor()),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      progressBar: true,
      progressAnimation: 'increasing',
    }),
    SalesforceChatModule,
    TranslateModule.forRoot(),
    NgChartsModule.forRoot({
      defaults: {},
      plugins: [Annotation],
      generateColors: false,
    }),
    IMaskModule,
    ChartModule,
    MoveFormRoutingModule,
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [HttpClient, KeycloakService, AppConfig, TranslateService, DeviceService],
    },
    {
      provide: MissingTranslationHandler,
      useClass: MyMissingTranslationHandler,
    },
    CookieService,
    { provide: LOCALE_ID, useValue: 'fr-BE' },
    LowerCasePipe,
    AddDaysToDatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

_('general.meterType.dual');
_('general.meterType.dualExclusiveNight');
_('general.meterType.mono');
_('general.meterType.monoExclusiveNight');
_('general.meterType.smartDual');
_('general.meterType.smartDualExclusiveNight');
_('general.meterType.smartMono');
_('general.meterType.smartMonoExclusiveNight');
_('general.meters.HIGH.title');
_('general.meters.LOW.title');
_('general.meters.PRODUCTION_HIGH.title');
_('general.meters.PRODUCTION_LOW.title');
_('general.meters.NIGHT_EXCLUSIVE.title');
_('general.meters.TOTAL_HOUR.title');
_('general.units.kwh');
_('general.units.m3');
_('general.consumptionIn.kwh');
_('general.consumptionIn.m3');
_('general.save');
_('general.upsell.boiler_one_shot.confirmation.newsletter-confirmation');
_('general.upsell.boiler_one_shot.contact_info.email.text');
_('general.upsell.boiler_one_shot.simulation.boiler.boiler_maintenance_one_shot_fuel_oil');
_('general.upsell.boiler_one_shot.simulation.boiler.boiler_maintenance_one_shot_gas');
_('general.upsell.boiler_one_shot.simulation.boiler.high');
_('general.upsell.boiler_one_shot.simulation.boiler.low');
_('general.upsell.return');
_('general.upsell.return.home');
_('general.meters.PRODUCTION_TOTAL_HOUR.title');
_('general.meters.PRODUCTION_HIGH.title');
_('general.meters.PRODUCTION_LOW.title');
_('general.injection');
_('errorMessages.RegistrationInProgress');
_('errorMessages.higherThanAverage');
_('errorMessages.minimumNotReached');
_('components.greenCertificate.discontinuation.alert');
_('components.datePicker.predefinedDates.thisYear');
_('components.datePicker.predefinedDates.thisMonth');
_('components.datePicker.predefinedDates.thisWeek');
_('components.datePicker.predefinedDates.last12Months');
_('components.datePicker.predefinedDates.yesterday');
_('components.datePicker.predefinedDates.pastYears');
_('components.datePicker.predefinedDates.customRange');
_('components.consumption.totalConsumptionBox.dateTitles.toPresent');
_('components.consumption.totalConsumptionBox.dateTitles.theMonth');
_('components.consumption.totalConsumptionBox.dateTitles.weekFrom');
_('components.consumption.totalConsumptionBox.dateTitles.to');
_('components.greenCertificate.discontinuation.end');
_('components.indexModal.editIndexTitle');
_('components.pageError.title');
_('components.pageError.description');
_('components.pageError.unauthorized.title');
_('components.pageError.unauthorized.description');
_('components.consumption.index.intro');
_('components.consumption.index.editIndex');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.PRODUCTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.TOTAL_HOUR.INJECTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.HIGH.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.HIGH.PRODUCTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.HIGH.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.HIGH.INJECTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.LOW.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.LOW.PRODUCTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.LOW.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.LOW.INJECTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.MONO.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.MONO.PRODUCTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.MONO.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.MONO.INJECTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.PRODUCTION.label');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.PRODUCTION.tooltip');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.INJECTION.label');
_('components.estimatedIndexDetail.form.meterInput.NIGHT_EXCLUSIVE.INJECTION.tooltip');
_('components.balance.negative.explanation');
_('components.consumption.powerPeak.questionsAndAnswers.0.title');
_('components.consumption.powerPeak.questionsAndAnswers.0.description');
_('components.consumption.powerPeak.navigation.0');
_('components.consumption.powerPeak.navigation.1');
_('components.consumption.powerPeak.navigation.2');
_('components.consumption.disclaimer.activeMandates.energyType.electricity');
_('components.consumption.disclaimer.activeMandates.energyType.gas');
_('components.consumption.disclaimer.activeMandates.mandateType.quarter-hour');
_('components.consumption.disclaimer.activeMandates.mandateType.hour');
_('components.consumption.disclaimer.activeMandates.mandateType.day');
_('components.consumption.disclaimer.activeMandates.mandateType.bothElec');
_('components.consumption.disclaimer.activeMandates.mandateType.bothGas');
_('components.advancePayment.alert.advanceChangeInProgress');
_('components.advancePayment.alert.advanceChangeError');
_('errorMessages.fieldValidationErrorMsg');
_('errorMessages.invalidZipCode');
_('common.country.belgium');
