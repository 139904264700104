<div class="w-full px-8 xl:max-w-screen-xl xl:w-full xl:mx-auto md:mt-20">
  <div class="flex flex-wrap items-center">
    <div class="md:flex-1 order-2 my-16 ml-4 md:my-0 md:ml-0">
      <div class="text-center md:max-w-xl md:mx-auto">
        <h3 class="text-center mb-4 font-medium font-sans">
          {{ config.title | translate }}
        </h3>
        <p class="font-sans mb-8" [innerHTML]="config.content | translate"></p>
        <button
          class="btn btn-primary"
          (click)="backToConsumptions()"
          [innerHTML]="config.ctaLabel | translate"
        ></button>
      </div>
    </div>
  </div>
</div>
