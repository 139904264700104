import { DestroyRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessControlDirective } from './access-control.directive';
import {
  AccessRight,
  AccessRightOperator,
  AccessRights,
} from '../resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface MultipleAccessRight {
  rights: AccessRight[];
  operator?: AccessRightOperator; // operator is optional, default operator will be AND
  additionalCondition?: boolean; // additional condition to access rights
}
@Directive({
  selector: '[multipleAccessControl]',
})
export class MultipleAccessControlDirective extends AccessControlDirective implements OnInit {
  private accessRightCondition: MultipleAccessRight;

  constructor(
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    protected facade: MainFacade,
    protected destroyRef: DestroyRef
  ) {
    super(templateRef, viewContainer, facade, destroyRef);
  }

  @Input() set multipleAccessControl(accessRightCondition: MultipleAccessRight) {
    this.accessRightCondition = accessRightCondition;
    if (accessRightCondition) {
      // set default conjunction operator (AND) if is it missing
      accessRightCondition.operator = accessRightCondition.operator || AccessRightOperator.conjunction;

      // update view in parent class
      this.updateView(this.checkAccessCondition(accessRightCondition));
    }
  }

  ngOnInit() {
    this.facade.accessRights$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((accessRights: AccessRights) => {
      if (!accessRights) {
        return;
      }

      // current access backup
      const previousAccess = this.checkAccessCondition(this.accessRightCondition);

      this.accessRights = accessRights;

      const currentAccess = this.checkAccessCondition(this.accessRightCondition);
      // call updateView method when the access was changed only
      if (previousAccess !== currentAccess) {
        // update view in parent class
        this.updateView(currentAccess);
      }
    });
  }

  private checkAccessCondition(accessRightCondition: MultipleAccessRight): boolean {
    if (!this.accessRightCondition) {
      return false;
    }

    // operator AND
    if (accessRightCondition.operator === AccessRightOperator.conjunction) {
      return (
        accessRightCondition.rights.every((accessRight: AccessRight) => this.checkAccess(accessRight)) &&
        (accessRightCondition.additionalCondition === undefined || accessRightCondition.additionalCondition)
      );
    }
    // operator OR
    if (accessRightCondition.operator === AccessRightOperator.disjunction) {
      return (
        accessRightCondition.rights.some((accessRight: AccessRight) => this.checkAccess(accessRight)) ||
        accessRightCondition.additionalCondition === undefined ||
        accessRightCondition.additionalCondition
      );
    }
  }
}
